import axios from 'axios';
import api from '../api';
import { history } from '../../store/store';
import endpoints from '../endpoints';
import AuthService from '../../auth/auth.service';

const TOKEN_REFRESH_TIMEOUT = 1 * 60 * 1000; // milliseconds = minutes * (seconds/minute * milliseconds/second)

const getHeaders = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `${AuthService.getToken()}`,
});

export default {
    changePassword(email, oldPassword, newPassword) {
        return api.post(
            endpoints.AUTH_CHANGE_PASSWORD,
            {
                email_address: email,
                password: oldPassword,
                new_password: newPassword,
            },
            'Error changing password on server'
        );
    },

    forgotPassword(email) {
        return api.post(
            endpoints.AUTH_FORGOT_PASSWORD,
            {
                email_address: email,
            },
            'Error initiating process of resetting user password'
        );
    },
    resendCode(email) {
        return api.post(
            endpoints.AUTH_RESEND,
            {
                email_address: email,
            },
            'Error resending a new code to user'
        );
    },

    confirm(email, confirmationCode, newPassword) {
        return axios
            .post(
                endpoints.AUTH_RESET_CONFIRM,
                {
                    email_address: email,
                    confirmation_code: confirmationCode,
                    new_password: newPassword,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then(() => {
                history.push('/');
            })
            .catch((err) => {
                let message = 'Error confirming new password with server. Check email and confirmation code.';
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }
                throw message;
            });
    },
    reset(email) {
        return api.post(
            endpoints.AUTH_RESET,
            {
                email_address: email,
            },
            `Error requesting password reset for ${email}`
        );
    },
    signIn(email, password) {
        return axios
            .post(
                endpoints.AUTH_SIGN_IN,
                {
                    email_address: email,
                    password,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => {
                AuthService.signIn(
                    response.data.access_token,
                    response.data.user,
                    response.data.refresh_token,
                    response.data.id_token
                );
            })
            .catch((error) => {
                let message = 'Error signing into account from the server.';
                if (error && error.response && error.response.data) {
                    message = error.response.data.message;
                } else if (error && error.request && error.request.data) {
                    message = error.request.data.message;
                }
                throw message;
            });
    },
    init_auth(email, password) {
        return axios
            .post(
                endpoints.AUTH_INIT,
                {
                    email_address: email,
                    password,
                    refresh_token: AuthService.getRefreshToken(),
                    device_key: AuthService.getDeviceKey(),
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => {
                if (response.data.session) {
                    // Need to go through completion of the sms token exchange
                    return {
                        need_mfa: false,
                        session: response.data.session,
                    };
                }
                AuthService.signIn(
                    response.data.access_token,
                    response.data.user,
                    response.data.refresh_token,
                    response.data.id_token
                );
            })
            .catch((error) => {
                let message = 'Error signing into account from the server.';
                if (error && error.response && error.response.data) {
                    message = error.response.data.message;
                } else if (error && error.request && error.request.data) {
                    message = error.request.data.message;
                }
                throw message;
            });
    },
    confirm_auth(email, password, sms_code, session, remember_device) {
        return axios
            .post(
                endpoints.AUTH_CONFIRM,
                {
                    email_address: email,
                    password,
                    remember_device,
                    session,
                    sms_code,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => {
                AuthService.signIn(
                    response.data.access_token,
                    response.data.user,
                    response.data.refresh_token,
                    response.data.id_token
                );
                AuthService.setDeviceKey(response.data.DeviceKey);
                AuthService.setDeviceKeyGroup(response.data.device_group);
            })
            .catch((error) => {
                let message = 'Error signing into account from the server.';
                if (error && error.response && error.response.data) {
                    message = error.response.data.message;
                } else if (error && error.request && error.request.data) {
                    message = error.request.data.message;
                }
                throw message;
            });
    },
    signUp(name, email, password, phoneNumber) {
        AuthService.setName(name);
        AuthService.setEmail(email);
        AuthService.setPhoneNumber(phoneNumber);
        return axios
            .post(
                endpoints.AUTH_SIGN_UP,
                {
                    name,
                    email_address: email,
                    password,
                    phone_number: phoneNumber,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => {
                AuthService.clearName();
                AuthService.clearPhoneNumber();
            })
            .catch((err) => {
                let message = 'Error signing into account from the server.';
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }
                throw message;
            });
    },
    confirmSignup(confirmCode, emailAddress) {
        return axios
            .post(
                endpoints.AUTH_CONFIRM_SIGN_UP,
                {
                    code: confirmCode,
                    email_address: emailAddress,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => {
                AuthService.clearEmail();
            })
            .catch((err) => {
                let message = 'Error confirming sign up with the server.';
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }
                throw message;
            });
    },
    async refreshToken() {
        let _this = this;
        setTimeout(() => {
            _this.refreshToken.call(_this);
        }, TOKEN_REFRESH_TIMEOUT);
        let token = await AuthService.getToken();
        let refreshToken = await AuthService.getRefreshToken();
        let idToken = await AuthService.getIdToken();
        let user = await AuthService.getUser();
        let device_key = await AuthService.getDeviceKey();
        if (user) {
            let email = user.email_address;
            let idx = email.indexOf('@');
            let username = email.slice(0, idx);
            if (!token || !refreshToken || !idToken) {
                return;
            }

            return axios
                .post(
                    endpoints.AUTH_REFRESH,
                    {
                        refresh_token: refreshToken,
                        access_token: token,
                        id_token: idToken,
                        device_key,
                        username,
                        user,
                    },
                    {
                        headers: getHeaders(),
                    }
                )
                .then((response) => {
                    AuthService.setToken(response.data.access_token);
                    AuthService.setRefreshToken(response.data.refresh_token);
                    AuthService.setIdToken(response.data.id_token);
                    AuthService.setDeviceKey(response.data.device_key);
                    if (user) {
                        AuthService.setUser(user);
                    }
                })
                .catch(() => {
                    console.error();
                    AuthService.signOut();
                    history.push('/account/signin');
                });
        }
    },
    invite(name, emailAddress, phone) {
        return axios
            .post(
                endpoints.AUTH_INVITE,
                {
                    name,
                    email_address: emailAddress,
                    phone_number: phone,
                },
                {
                    headers: getHeaders(),
                }
            )
            .then((response) => response)
            .catch((err) => {
                let message = 'Error inviting the user with the server.';
                if (err?.response?.data) {
                    message = err.response.data.message;
                }
                throw message;
            });
    },
};
